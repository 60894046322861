import "./bootstrap";
import "../css/app.scss";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import mixpanel from "mixpanel-browser";

import { createSSRApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { VueReCaptcha } from "vue-recaptcha-v3";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import Popper from "vue3-popper";
import { QuillEditor } from "@vueup/vue-quill";
import * as Sentry from "@sentry/vue";

const appName = import.meta.env.VITE_APP_NAME;

/**
 * Track Page and Send to Google Analytic
 */
// if (import.meta.env.PROD && window.document.body.classList.contains('app-view')) {
//     router.on('navigate', (event) => {
//         window.gtag('js', new Date());
//         window.gtag('config', import.meta.env.VITE_GOOGLE_TAG_ID);
//     })
// }

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
    ignore_dnt: true,
});

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createSSRApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
            tracesSampleRate: parseFloat(
                import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? "0.5"
            ),

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        if (typeof props.initialPage.props.ziggy.routes !== "undefined") {
            Ziggy.routes = props.initialPage.props.ziggy.routes;
            Ziggy.defaults = props.initialPage.props.ziggy.defaults;
        }

        app.use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueReCaptcha, {
                siteKey: props.initialPage.props.captchaKey,
            })
            .component("Popper", Popper)
            .component("QuillEditor", QuillEditor)
            .mount(el);

        return app;
    },
    progress: {
        color: "#3202A6",
        delay: 300,
        showSpinner: true,
    },
});
