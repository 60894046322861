export const IS_SERVER = typeof window === 'undefined'

export const BUTTON_KINDS = [
    "primary",
    "primary-alt",
    "primary-bordered",
    "primary-inverted",
    "primary-inverted-alt",
    "success",
    "success-alt",
    "success-bordered",
    "success-inverted",
    "success-inverted-alt",
    "warning",
    "warning-alt",
    "warning-bordered",
    "warning-inverted",
    "warning-inverted-alt",
    "error",
    "error-alt",
    "error-bordered",
    "error-inverted",
    "error-inverted-alt",
    "info",
    "info-alt",
    "info-bordered",
    "info-inverted",
    "info-inverted-alt",
    "teal",
    "teal-alt",
    "teal-bordered",
    "teal-inverted",
    "teal-inverted-alt",
    "green",
    "green-alt",
    "green-bordered",
    "green-inverted",
    "green-inverted-alt",
    "emerald",
    "emerald-alt",
    "emerald-bordered",
    "emerald-inverted",
    "emerald-inverted-alt",
    "gray",
    "gray-alt",
    "gray-bordered",
    "gray-inverted",
    "gray-inverted-alt",
    "white",
    "white-primary",
    "black",
    "black-primary",
    "black-alt",
    "black-bordered",
    "facebook",
    "facebook-alt",
    "facebook-bordered",
    "twitter",
    "twitter-alt",
    "twitter-bordered",
    "tiktok",
    "tiktok-alt",
    "tiktok-bordered",
    "google",
    "google-alt",
    "google-bordered",
]
